/* Component Dependencies */
var adaptiveDestinationCarouselTemplate = require('templates/adaptiveDestinationCarousel.hbs');
var adaptiveDestinationCarouselListingsTemplate = require('templates/adaptiveDestinationCarousel-listings.hbs');
var AriesComponent = require('libs/aries-component');
var ResponsiveUtils = require('utils/responsiveUtils');
var $ = require('jquery');

AriesComponent.create({
  type: 'adaptiveDestinationCarousel',
  template: {
    'adaptiveDestinationCarousel': adaptiveDestinationCarouselTemplate,
    'adaptiveDestinationCarousel-listings': adaptiveDestinationCarouselListingsTemplate
  },
  events: {
    'click .slick-arrow': 'handleTabIndex',
    'mouseover .card-destination-link': 'handleTabIndex'
  },
  subscribe: {
    'WINDOW_LOAD_EVENT': 'handleTabIndex'
  },
  bindEvents: function() {
    var _self = this; 
    _self.$window = $(window);
    _self.initResponsiveUtils({ DESKTOP_VIEWPORT: 769});
    _self.toggleCarouselView();
  },
  handleTabIndex: function() {
    var _self = this;
    _self.$el.find('.card-destination-link').attr('tabindex', '-1');
    _self.$el.find('.card-destination-link.slick-active').attr('tabindex', '0');
  },
  //responsive util initialization 
	initResponsiveUtils: function (viewportConfig) {
    this.responsiveUtils = ResponsiveUtils.create({
      resizeEventHandler: _.bind(this.toggleCarouselView, this),
      viewport: viewportConfig
    });
    this.responsiveUtils.init();
  },

  //to check view ports and remove/add class for carousel
  toggleCarouselView: function(event) {
    var _self = this;
    
    var imageSection = _self.$el.find('.carousel-wrapper');
    // add wrapper class for Desktop
    if(_self.responsiveUtils.isDesktop()) {
      imageSection.addClass('stacked-img-wrapper');
    } else {
      imageSection.removeClass('stacked-img-wrapper');
    }
  }
});